<template>
  <ion-page class="bg-smoke">
    <div class="px-10 pt-12">
      <h2 class="mb-10">Verify your email</h2>
      <ValidationObserver ref="verufyEmailForm" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(attemptCode)">
          <div>
            <ValidationProvider mode="eager" rules="required" vid="code" name="Code" v-slot="{ errors }">
              <input
                v-model="form.code"
                type="text"
                class="form-input"
                :class="errors.length ? 'border border-red-600' : ''"
                placeholder="Your code"
              />
              <p v-if="errors.length" class="w-full text-red-600 mt-1 text-sm"> 
                {{ errors[0] }} 
              </p>
            </ValidationProvider>
          </div>

          <div class="w-full mt-6">
            <button
              class="btn-primary text-white bg-lightPurple w-full capitalize text-base block text-center"
              type="submit"
              :class="{'opacity-75': loading}"
            >
              {{ loading ? 'Verifying...' : 'Continue' }}
            </button>
          </div>
        </form>
      </ValidationObserver>

      <div class="w-full mt-2">
        <button
          @click.prevent="resend"
          class="btn-primary text-white bg-lightPurple w-full capitalize text-base block text-center"
          type="submit"
          :class="{'opacity-75': loadingResend}"
        >
          {{ loadingResend ? 'Resending...' : 'Resend code' }}
        </button>
      </div>

      <div class="absolute px-10 w-full left-0 right-0 bottom-0 pb-4">
        <form>
          <button
            @click.prevent="doSignOut"
            class="btn-primary text-white bg-lightPurple w-full capitalize text-base block text-center"
            type="button"
            :class="{'opacity-75': loadingSignOut}"
          >
            {{ loadingSignOut ? 'Signing out...' : 'Sign Out' }}
          </button>
        </form>
      </div>
    </div>
  </ion-page>
</template>

<script>
import { mapActions } from 'vuex';

import { VERIFY_EMAIL, SIGN_OUT } from '@/store/types/actions';

export default {
  data: () => ({
    loading: false,
    loadingResend: false,
    loadingSignOut: false,

    form: {
      code: ''
    }
  }),

  computed: {
    user() {
      return this.$store.state.auth.user
    }
  },

  methods: {
    ...mapActions({
      verifyEmail: `auth/${VERIFY_EMAIL}`,
      signOut: `auth/${SIGN_OUT}`,
      sendVerificationCode: `auth/sendVerificationCode`,
    }),

    attemptCode() {
      this.loading = true

      this.verifyEmail(this.form)
        .then(() => {
          this.$router.push({ name:'home' });
        })
        .catch(error => {
          this.loading = false

          this.$ionic
            .toastController.create({
              color: 'danger',
              message: error.response ? error.response.data.message : error,
              duration: 3000,
            })
            .then(toast => toast.present())
        });
    },

    resend() {
      this.loadingResend = true

      this.sendVerificationCode().then(() => {
        this.loadingResend = false
      })
    },

    doSignOut() {
      this.loadingSignOut = true
      this.signOut().then(() => {
        this.$router.push({name: 'signIn'})
      })
    }
  },

  mounted() {
    if (! this.user) {
      return this.$router.push({to: 'signIn'})
    }

    if (this.user.email_verified_at) {
      return this.$router.push({to: 'home'})
    }
  }
}
</script>
